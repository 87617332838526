<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Input Field</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Input Name *"
                    v-model="inputField.inputName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Name'"
                    @blur="$v.inputField.inputName.$touch()"
                    :is-valid="
                      $v.inputField.inputName.$dirty
                        ? !$v.inputField.inputName.$error
                        : null
                    "
                  >
                  </CInput>
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                      >Mandatory *
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="inputField.mandatory"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="true">Yes</option>
                        <option value="false" selected>No</option>
                      </select>
                    </div>
                  </div>
                  <!-- 
<CFormSelect size="sm" >
  <option>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</CFormSelect> -->
                  <CInput
                    label="Priority "
                    v-model="inputField.priority"
                    horizontal
                    :disabled="mode.viewMode"
                  />

                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Remarks *
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="inputField.needRemarks"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>

                  <MultiSelect
                    label="Input Type *"
                    :options="allInputs"
                    :hide-selected="false"
                    :searchable="true"
                    optionLabel="inputTypeName"
                    :disabled="mode.viewMode"
                    track-by="inputTypeId"
                    :track-ids.sync="inputField.inputTypeId"
                    idParamName="inputTypeId"
                    :itemTypeisString="false"
                    invalidFeedback="Select atleast one Input"
                  ></MultiSelect>

                  <!-- <MultiSelect
                    label="Date of Type *"
                    :options="allInputs"
                    :hide-selected="false"
                    :searchable="true"
                    optionLabel="inputTypeName"
                    :disabled="mode.viewMode"
                   
                    track-by="inputTypeId"
                    :track-ids.sync="inputField.inputTypeId"
                    idParamName="inputTypeId"
                    :itemTypeisString="false"
                    invalidFeedback="Select atleast one Input"
                  ></MultiSelect> -->

                  <div
                    class="form-group row"
                    v-if="
                      inputField.inputTypeId == 2 || inputField.inputTypeId == 4
                    "
                  >
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Type of Dates
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="inputField.typeofDate"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="1">Future</option>
                        <option value="2">Past</option>
                        <option value="3">Both</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="form-group row"
                    v-if="inputField.inputTypeId == 6"
                  >
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Doesn't Upload Images
                    </label>
                    <!-- <div class="col-sm-9">
                <div class="form-check">
							<input   class="form-check-input" type="radio" v-model="inputField.typeofCheck" value ='true' >  Yes 
  							<input  class="form-check-input" type="radio" v-model="inputField.typeofCheck" value ='false' checked>  No
						  </div>
								 </div>  -->

                    <div class="col-sm-9">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                          v-model="inputField.typeofCheck"
                          value="true"
                          :disabled="mode.viewMode"
                        />
                        <!-- <label class="form-check-label" for="exampleCheck1">Doesn't Upload Images</label> -->
                      </div>
                    </div>
                  </div>

                  <CInput
                    label="Hint "
                    v-model="inputField.hint"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Hint'"
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import MultiSelect from "../../containers/MultiSelect";
import { CFormSelect } from "@coreui/vue";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "InputField",
  components: {
    MultiSelect,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      inputFieldId: "",
      inputField: {
        inputFieldId: "0",
        inputName: "",
        priority: "0",
        inputTypeId: "",
        needRemarks: "false",
        hint: "",
        typeofDate: "",
        typeofCheck: "",
        mandatory: "false",
        sectionId: this.$route.params.sectionId,
      },
      allInputs: [],
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    inputField: {
      inputName: {
        required,
      },
      needRemarks: {
        required,
      },
      mandatory: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.sectionId = this.$route.params.sectionId;
      this.subSectionId = this.$route.params.subSectionId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.sectionId = this.$route.params.sectionId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.subSectionId = this.$route.params.subSectionId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.sectionId = this.$route.params.sectionId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.subSectionId = this.$route.params.subSectionId;
      this.getUser();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      //this.$router.push({ name: 'InputFields',params: {sectionId: this.$route.params.sectionId } })
      window.history.back();
    },
    saveSpeciality() {
      this.$v.inputField.$touch();
      if (this.$v.inputField.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.inputField));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/workPermit/sections/InputFields",
          this.inputField
        ).then((post) => {
          // this.$router.push({ name: "InputFields",params: {sectionId: this.$route.params.sectionId }});
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/workPermit/section/InputField/" +
          this.inputFieldId
      )
        .then((response) => response.json())
        .then((data) => (this.inputField = data));
    },
    getInputs() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/meta/workPermit/inputFields/InputTypes"
      )
        .then((response) => response.json())
        .then((data) => (this.allInputs = data));
    },
  },
  mounted() {
    this.getInputs();
  },
};
</script>
